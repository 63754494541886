<template>
  <div class="meal">
  <div class="meal-description">
    
 
      <div class="meal-title">
    <h3>{{ meal.fields["Name"] }}   
      <img
        v-if="meal.fields['Veggie']"
        src="~@/assets/img/veggie-icon.png"
        width="15"
        height="15"
        class="veggie-icon"
      /> 
      </h3>
    
    <p v-if="meal.fields['Description']">{{ meal.fields["Description"] }}</p>
    </div>
  </div>
  <div class="meal-actions">
    <p>{{ meal.fields["Prix de vente TTC"] }}€</p>
    <div class="quantity-selector">
      <select v-model="quantity" id="quantity">
        <option value=""></option>
        <option v-for="number in 50" :key="number" :value="number">{{ number }}</option>
      </select>
    </div>
  </div>
  </div>


</template>

<script>
export default {
  props: {
    meal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      quantity: '', // Default quantity is empty
      photo_array : this.meal.fields["Photo"],
      photo_url:false,
           

    };
  },

  watch: {
  quantity(newValue) {
   
      this.$emit('updateQuantity', { meal: this.meal, quantity: Number(newValue) });
    
  }
},
};
</script>

<style scoped>
.meal {
  display: flex;
  align-items: center;
  padding: 0.2em 0em;
  justify-content: space-between;
}



.meal-description h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 300;
  text-transform: capitalize;
}

.meal-description{
  text-align: start;
  padding-right: 20px;
}
.meal-description p{
  margin: 0;
  font-weight: 200;
  font-size: 14px;
  margin-bottom:10px
  
}
.meal-actions p{
  padding-right: 10px;
  margin-block-start:7px;
  margin-block-end: 7px;
}



.meal-actions{
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

@media (max-width: 991px) {
  .meal-description h3 {
    font-size: 14px;
  }
  .meal-description p{
  
  font-size: 12px;
  
}
.meal-actions p{
   font-size: 14px;
}


}


</style>

