<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap"
    rel="stylesheet"
  />


  <div id="app">
    <header>
      <h1>La Belle Table</h1>
    </header>
    <main>
       

        <!-- Confirmation Page -->
        <div  v-show="showConfirmationPage" class="confirmation-page">
          <h2>Order Confirmation</h2>
           <p>Thank you for your order!</p>
    <img src="@/assets/img/confirmation-anim.gif" alt="Order Confirmation">
         
          <p>Your order number is: {{ order_number }}</p>
          <p>Your order form has been sent to : {{ order_mail }}. Please send this order form to your contact person to confirm the order.</p>
          <button @click="refreshPage">Back to Menu</button>
        </div>
     
      <div v-show="!showConfirmationPage">
      <div class="floating-controls">
        <button @click="scrollToSection('food')">Food</button>
        <button @click="scrollToSection('drinks')">Drinks</button>
        <button @click="scrollToSection('order')">Order</button>
      </div>
      <div class="sticky-banner top">
        Select your items and enter your email to receive your order form
      </div>
      <div class="photo-icon-container" @click="openPhotoModal">
        
        <img
          src="@/assets/img/icone_appareil_photo.svg"
          width="20"
          height="20"
          class="photo-icon"
          alt="open photo"
          title="open photo"
          
        />
      </div>
     <div v-if="showModal" class="photo-scroll-modal" @click="closePhotoModal">
        <div class="photo-scroll-container" @click.stop>
          <img v-for="index in totalImages" :key="index" :src="getImagePath(index - 1)" alt="Menu Photo" class="scroll-photo">
        </div>
        <button class="close-button" @click="closePhotoModal">X</button>
      </div>
     
    
      <div  style="display: flex; justify-content: center; flex-wrap: wrap">
                  <div style="margin-top: -8px" v-if="isLoadingCategory" class="loading-overlay-photo" id="food-part"></div>

        <div v-else style="margin-top: -8px">
          <img
            id="food-part"
            src="@/assets/img/food-menu.webp"
            style="width: 100vw"
           
          />
        </div>
        <div class="row">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
           v-else
            :key="'Breakfast'"
            :category="findCategory('Breakfast')"
            @updateOrder="updateOrder"

          />
        </div>
        <div class="row">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Starters'"
            :category="findCategory('Starters')"
            @updateOrder="updateOrder"
          />
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Sandwiches'"
            :category="findCategory('Sandwiches')"
            @updateOrder="updateOrder"
          />
          </div>
        <div class="row">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Caviar'"
            :category="findCategory('Caviar')"
            @updateOrder="updateOrder"
          />
          </div>
        <div class="row">
          <h2>Snack Trays</h2>
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Snack Trays'"
            :category="getFirstHalf('Snack Trays')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
           <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Snack Trays'"
            :category="getSecondHalf('Snack Trays')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
        </div>
        <div class="row">
            <h2>Main Courses</h2>
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Main Courses'"
            :category="getFirstHalf('Main Courses')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
           <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Main Courses'"
            :category="getSecondHalf('Main Courses')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
        </div>
        <div class="row">
            <h2>Desserts</h2>
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Desserts'"
            :category="getFirstHalf('Desserts')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
               <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Desserts'"
            :category="getSecondHalf('Desserts')"
            @updateOrder="updateOrder"
            :notitle="true"
          />
        </div>
        <div style="margin-top: -8px">
          <img
            id="drinks-part"
            src="@/assets/img/drinks-menu.webp"
            style="width: 100vw"
          
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Soft Drink'"
            :category="findCategory('Soft Drinks')"
            @updateOrder="updateOrder"
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Water'"
            :category="findCategory('Water')"
            @updateOrder="updateOrder"
          />
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Fruit Juice'"
            :category="findCategory('Fruit Juice')"
            @updateOrder="updateOrder"
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Wines (Rose)'"
            :category="findCategory('Wines (Rose)')"
            @updateOrder="updateOrder"
          />
     
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Wines (White)'"
            :category="findCategory('Wines (White)')"
            @updateOrder="updateOrder"
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Wines (Red)'"
            :category="findCategory('Wines (Red)')"
            @updateOrder="updateOrder"
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Champagne (Brut)'"
            :category="findCategory('Champagne (Brut)')"
            @updateOrder="updateOrder"
          />
     
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Champagne (Rose)'"
            :category="findCategory('Champagne (Rose)')"
            @updateOrder="updateOrder"
          />
        </div>
  
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Champagne (Prestigious)'"
            :category="findCategory('Champagne (Prestigious)')"
            @updateOrder="updateOrder"
          />
        </div>
        <div class="row closer">
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Spirits'"
            :category="findCategory('Spirits')"
            @updateOrder="updateOrder"
          />
          <div  v-if="isLoadingCategory" class="loading-overlay"></div>
          <CategoryComponent
             v-else
            :key="'Beers'"
            :category="findCategory('Beers')"
            @updateOrder="updateOrder"
          />
        </div>
      </div>

      <OrderComponent
        :orderItems="orderItems"
        @order-submitted="handleOrderSubmission"
        :isLoading="isLoading"
        :submissionMessage="submissionMessage"
        id="order-part"
      />
      <div
        class="sticky-banner bottom"
        id="food-bottom-banner"
        v-if="this.foodVisible"
      >
        <span> All our dishes are homemade </span>
        <span style="display: flex; align-items: center">
          <img
            src="~@/assets/img/veggie-icon.png"
            width="15"
            height="15"
            style="margin-right: 5px"
          />
          Vegetarian dishes
        </span>
      </div>
      <div
        class="sticky-banner bottom"
        id="drinks-bottom-banner"
        v-if="this.drinksVisible"
      >
        <span>
          For last minute requests, please note that some bottles may not be
          available
        </span>
      </div>
    </div>
    </main>
  </div>
</template>

<script>
import CategoryComponent from "./components/CategoryComponent.vue";
import OrderComponent from "./components/OrderComponent.vue";
import Airtable from "airtable";

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY,
}).base("appyCRVSVGQiMm80A");

const axios = require("axios");

export default {
  name: "App",
  components: {
    CategoryComponent,
    OrderComponent,
  },
  data() {
    return {
      meals: [],
      orderItems: [],
      categories: [],
      isLoading: false,
      submissionMessage: "",
      order_number: "",
      order_mail: "",
      order_items_mail: [],
      showModal: false,
      mealIndexMap: [],
      currentImageIndex: 0,
      totalImages: 3,
      isLoadingCategory: true,
      foodVisible: false,
      drinksVisible: false,
      orderVisible: false,
          showConfirmationPage: false,  

      categories_to_fetch: [
        "Breakfast",
        "Starters",
        "Sandwiches",
        "Caviar",
        "Snack Trays",
        "Main Courses",
        "Desserts",
        "Soft Drinks",
        "Water",
        "Fruit Juice",
        "Wines (Rose)",
        "Wines (White)",
        "Wines (Red)",
        "Champagne (Brut)",
        "Champagne (Rose)",
        "Champagne (Prestigious)",
        "Spirits",
        "Beers",
      ],
    };
  },

  methods: {
     refreshPage() {
    location.reload();
  },
    preloadAssets() {
      const preloadLinks = [
        require('@/assets/img/drinks-menu.webp'),
        require('@/assets/img/food-menu.webp'),
        require('@/assets/img/mosaic/1.webp'),
        require('@/assets/img/mosaic/2.webp'),
        require('@/assets/img/mosaic/3.webp')
      ];

      preloadLinks.forEach(link => {
        const preloadLink = document.createElement('link');
        preloadLink.rel = link.includes('drinks-menu') || link.includes('food-menu') ? 'preload' : 'prefetch';
        preloadLink.as = 'image';
        preloadLink.href = link;
        document.head.appendChild(preloadLink);
      });
    },
  
    openPhotoModal() {
      this.showModal = true; // Show the modal
    },
    closePhotoModal() {
      this.showModal = false; // Hide the modal
    },
    navigateImage(direction) {
      if (direction === "next") {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.totalImages;
      } else if (direction === "prev") {
        this.currentImageIndex =
          (this.currentImageIndex - 1 + this.totalImages) % this.totalImages;
      }
    },
    scrollToSection(section) {
      if (section === "food") {
        document
          .getElementById("food-part")
          .scrollIntoView({ behavior: "smooth" });
      } else if (section === "drinks") {
        document
          .getElementById("drinks-part")
          .scrollIntoView({ behavior: "smooth" });
      } else if (section === "order") {
        document
          .getElementById("order-part")
          .scrollIntoView({ behavior: "smooth" });
      }
    },
    updateHighlight() {
    const windowHeight = window.innerHeight;
    const foodElement = document.getElementById("food-part");
    const drinksElement = document.getElementById("drinks-part");
    const orderElement = document.getElementById("order-part");

    const foodPosition = foodElement ? foodElement.getBoundingClientRect() : null;
    const drinksPosition = drinksElement ? drinksElement.getBoundingClientRect() : null;
    const orderPosition = orderElement ? orderElement.getBoundingClientRect() : null;

    const isDrinksVisible = drinksPosition && foodPosition
      ? drinksPosition.top >= foodPosition.bottom &&
        drinksPosition.top < windowHeight
      : false;
    const isOrderVisible = orderPosition && drinksPosition
      ? orderPosition.top >= drinksPosition.bottom &&
        orderPosition.top < windowHeight
      : false;
    const isFoodVisible = !isDrinksVisible && !isOrderVisible;

    this.foodVisible = isFoodVisible;
    this.drinksVisible = isDrinksVisible;
    this.orderVisible = isOrderVisible;

    const buttons = document.querySelectorAll(".floating-controls button");
    buttons.forEach((button) => button.classList.remove("active"));

    if (this.orderVisible) {
      buttons[2].classList.add("active");
    } else if (this.drinksVisible) {
      buttons[1].classList.add("active");
    } else if (this.foodVisible) {
      buttons[0].classList.add("active");
    }
  },
    getImagePath(index) {
      return require(`@/assets/img/mosaic/${index + 1}.webp`);
    },
    updateOrder(payload) {
      const existingOrderIndex = this.orderItems.findIndex(
        (order) => order.meal.id === payload.meal.id
      );
      if (existingOrderIndex !== -1) {
        if (payload.quantity === 0 || payload.quantity === "") {
          this.orderItems.splice(existingOrderIndex, 1);
        } else {
          this.orderItems[existingOrderIndex].quantity = payload.quantity;
        }
      } else if (payload.quantity !== 0 && payload.quantity !== "") {
        this.orderItems.push({
          meal: payload.meal,
          quantity: payload.quantity,
        });
      }
      this.orderItems.sort((a, b) => {
        let indexA = this.mealIndexMap[a.meal.id];
        let indexB = this.mealIndexMap[b.meal.id];
        return indexA - indexB;
      });
    },
    async handleOrderSubmission(orderDetails) {
      this.isLoading = true;
      this.submissionMessage = "";
      let createCommandeResponse = null;
      try {
        if(orderDetails.date ==""){
          createCommandeResponse = await base("Commandes").create([
          {
            fields: {
              "Mail prise de commande": orderDetails.email,
              "Statut d'acceptation": "En attente de validation",
              "Nom du bateau (Webapp)": orderDetails.boat,
              "Nom du client (Webapp)": orderDetails.name,
              "Prénom du client (Webapp)": orderDetails.firstName,
               "Commentaires (Webapp)": orderDetails.notes,
              Factures: ["reckYm2HBBy1RhfpH"],
            },
          },
        ]);
        }
        else{
         createCommandeResponse = await base("Commandes").create([
          {
            fields: {
              "Mail prise de commande": orderDetails.email,
              "Statut d'acceptation": "En attente de validation",
              "Nom du bateau (Webapp)": orderDetails.boat,
              "Nom du client (Webapp)": orderDetails.name,
              "Prénom du client (Webapp)": orderDetails.firstName,
              "Date de livraison": orderDetails.date,
               "Commentaires (Webapp)": orderDetails.notes,
              Factures: ["reckYm2HBBy1RhfpH"],
            },
          },
        ]);}

        const commandeId = createCommandeResponse[0].id;
        this.order_number =
          createCommandeResponse[0].fields["Référence de commande"];

        this.order_mail = orderDetails.email;

        const produitVendusRecords = orderDetails.orderItems.map((item) => ({
          fields: {
            Commande: [commandeId],
            Produit: [item.meal.id],
            "Quantité commandée": item.quantity,
          },
        }));
        this.order_items_mail = orderDetails.orderItems.map((item) => ({
          name: item.meal.fields["Name"],
          quantity: item.quantity,
        }));

        for (const record of produitVendusRecords) {
          await base("Produits vendus").create([record]);
        }


        let webhookUrl = process.env.VUE_APP_ORDER_SUBMISSION_WEBHOOK;

        let data = {
          order_number: this.order_number,
          order_mail: this.order_mail,
          order_items: this.order_items_mail,
          airtable_record: commandeId,
        };

        try {
          const response = await axios.post(webhookUrl, data);

          this.sendMail(response.data,orderDetails);
          
          
        } catch (error) {
          console.error(error);
        }
        this.submissionMessage =
          "Your order form has been sent. Please send this order form to your contact person to confirm the order.";
            this.showConfirmationPage = true;

      } catch (error) {
        console.error("Error submitting order:", error);
        this.submissionMessage =
          "Failed to submit the order. Please try again.";
      } finally {
        this.isLoading = false;
      }
    },
    fetchMealsByCategory(category) {
      return new Promise((resolve, reject) => {
        base("Produits")
          .select({
            view: "viwSvBsqhkFSgN5X9",
            filterByFormula: `{Catégorie} = '${category}'`,
          })
          .all()
          .then((records) => {
            resolve(records);
          })
          .catch((err) => reject(err));
      });
    },
    fetchMealsForCategories(categories) {
      this.isLoadingCategory = true;

      const fetchPromises = categories.map((category) =>
        this.fetchMealsByCategory(category)
      );

      Promise.all(fetchPromises)
        .then((results) => {
        this.meals=[].concat(...results);
          this.categories = categories.map((category, index) => ({
            name: category,
            meals: results[index],
          }));
          this.buildMealIndexMap();
          this.isLoadingCategory = false;
        })
        .catch((error) => {
          console.error("Error fetching categories: ", error);
          this.isLoadingCategory = false;
        });
    },
    buildMealIndexMap() {
      this.mealIndexMap = this.meals.reduce((map, meal, index) => {
        map[meal.id] = index;
        return map;
      }, {});
    },
    categorizeMeals() {
      let categoryMap = {};
      this.meals.forEach((meal) => {
        let categoryName = meal.fields["Catégorie"];
        if (!categoryMap[categoryName]) {
          categoryMap[categoryName] = [];
        }
        categoryMap[categoryName].push(meal);
      });
      this.categories = Object.keys(categoryMap).map((key) => ({
        name: key,
        meals: categoryMap[key],
      }));
    },
    findCategory(name) {
      return this.categories.find((category) => category.name === name);
    },
    getFirstHalf(name) {
      const category = this.findCategory(name);
      if (category) {
        return {
          name: category.name,
          meals: category.meals.slice(0, Math.ceil(category.meals.length / 2)),
        };
      }
      return null;
    },
    getSecondHalf(name) {
      const category = this.findCategory(name);
      if (category) {
        return {
          name: category.name,
          meals: category.meals.slice(Math.ceil(category.meals.length / 2)),
        };
      }
      return null;
    },
    async sendMail(data, orderDetails) {
      let brevoApiUrl = "https://api.brevo.com/v3/smtp/email";
    const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

      let sendSmtpEmail = {
        templateId: 5,
        attachment: [
          {
            url: data.file_url,
            name: data.file_name,
          },
        ],
        to: [{ email: this.order_mail }],
        bcc: [{ email: "contact@labelle-table.com" }],
        params: {
          order_number: this.order_number,
          items: this.order_items_mail,
          boat: orderDetails.boat,
            last_name: orderDetails.name,
             first_name: orderDetails.firstName,
              delivery_date: formatDate(orderDetails.date),
               notes: orderDetails.notes,

        },
      };

      try {
        const response = await axios.post(brevoApiUrl, sendSmtpEmail, {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            "api-key": process.env.VUE_APP_BREVO_API_KEY,
          },
        });
        console.log(
          "API called successfully. Returned data: " +
            JSON.stringify(response.data)
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
        this.preloadAssets();
    this.fetchMealsForCategories(this.categories_to_fetch);
    window.addEventListener("scroll", this.updateHighlight);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.updateHighlight);
  },
  created() {
    document.title = "Catering Menu 2024";
  },
};
</script>

<style>
@font-face {
  font-family: "Glamora";
  src: url("~@/assets/fonts/GLAMORA.otf");
}
@font-face {
  font-family: "Parisienne";
  src: url("~@/assets/fonts/Parisienne-Regular.ttf");
}
body {
  font-family: "Poppins", sans-serif;
  background: #fcf7ed;
  color: #0e3141;
  line-height: 1.6;
  font-weight: 300;
  margin: 0;
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

h2 {
  font-family: "Glamora";
  color: #c09b6e;
  text-align: center;
   font-size: 1.5em;
  margin-bottom: 1em;
  width: 100%;
}


@media (max-width: 991px) {

  h2 {
    font-size: 20px
  }

}
@media (max-width: 723px) {
 
h2 {
    font-size: 16px
  }
}
h1 {
  display: none;
}

button,
select {
  padding: 0.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Poppins";
}

button:hover {
  opacity: 0.9;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}
.photo-modal {
  position: fixed; /* Positioned relative to the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  display: flex;
  align-items: center; /* Aligns vertically */
  justify-content: center; /* Aligns horizontally */
  z-index: 1000; /* Ensures the modal is above other content */
}

.photo-container {
  max-width: 35%; /* Limits the photo's width to prevent it from being too large */
  max-height: 100%; /* Limits the photo's height to prevent it from being too large */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
}

.photo-container img {
  max-width: 100%; /* Makes the image responsive */
  max-height: 100%; /* Makes the image responsive */
  object-fit: contain; /* Ensures the photo's aspect ratio is preserved */
}

.photo-scroll-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  z-index: 1000;
  overflow-y: scroll; /* Allows scrolling */
  padding-top: 20px; /* Spacing for scrolling comfort */
}
.photo-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-photo {
  max-width: 90%;
  margin-bottom: 20px; /* Space between images */
  object-fit: contain;
}

.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 8px;
  font-weight: bold;
}
/* Style adjustments for better visibility and aesthetics */
.close-button:hover {
  background-color: #eaeaea; /* Slightly darker background on hover for feedback */
}
.photo-icon-container {
  position: fixed;
  top: 30px;
  right: 10px;
  background-color: #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  line-height: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.photo-icon {
  cursor: pointer;
  fill: #000; /* Change the fill color if needed */
  transition: fill 0.2s ease-in-out;
}

.nav-button {
  position: absolute;
  top: 50%;
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-top: -20px;
  z-index: 101; /* Ensure they are clickable and above other content */
}

.left-arrow {
  left: -35px;
}

.right-arrow {
  right: -35px;
}

.nav-button:hover {
  background-color: #eaeaea;
}
.sticky-banner {
  position: sticky;
  background-color: #0e3141;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 2px 10px;
  font-style: italic;
  z-index: 1000; /* Ensure it's above other content */
}
.top {
  top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bottom {
  bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#food-bottom-banner {
  display: flex;
  justify-content: space-around;
}
.floating-controls {
  position: fixed;
  top: 30px;
  left: 10px;
  z-index: 1050; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.floating-controls button {
  padding: 10px;
  width: 100%;
  border: none;
  background-color: #f2f2f2;
  cursor: pointer;
  transition: background-color 0.3s;
}

.floating-controls button:hover {
  background-color: #e0e0e0;
}

.floating-controls button.active {
  color: #fff;
  background-color: #0e3141;
}

@media (max-width: 991px) {
  .row {
    flex-wrap: wrap;
  }
  .photo-container {
    max-width: 40%; /* Limits the photo's width to prevent it from being too large */
  }
}
@media (max-width: 700px) {
  .photo-container {
    max-width: 60%; /* Limits the photo's width to prevent it from being too large */
  }
  .sticky-banner {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .photo-container {
    max-width: 80%; /* Limits the photo's width to prevent it from being too large */
  }
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  z-index: 1000;
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading-overlay {
  position: relative;
  width: 50%;
  height: 300px;
  background-color: rgba(200, 200, 200, 0.5); /* Gray overlay */

margin:15px;
  color: #fff;
  border-radius: 5px;
}
.loading-overlay-photo {
  position: relative;
  width: 100%;
  height: 214px;
  background-color: rgba(200, 200, 200, 0.5); /* Gray overlay */
  color: #fff;
  border-radius: 5px;
}
:root {
   --dp-font-family: "Poppins";
   --dp-font-size: 14px;
       --dp-input-padding:7px;
       --dp-border-radius: 5px;
       --dp-text-color: #0e3141;
      
}
.dp__theme_light{
 --dp-border-color:#ccc;
}
.dp__input{
  margin: 7px 0;
}
.confirmation-page{
  text-align: center;
}
.confirmation-page button {
  padding: 10px;
  border: none;
    color: #fff;
  background-color: #0e3141;
  cursor: pointer;
  transition: background-color 0.3s;
}


</style>
