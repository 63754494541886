<template>
  <div class="category">
    
    <h2 v-if="!notitle">{{ category.name }}</h2>

    <MealComponent
      v-for="meal in category.meals"
      :key="meal.id"
      :meal="meal"
      @updateQuantity="handleQuantityUpdate" />
  </div>
</template>

<script>
import MealComponent from './MealComponent.vue';

export default {
  components: {
    MealComponent
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    notitle :{
      type: Boolean
    }
  },
   data() {
    return {
      space:"&nbsp"
    }},
  methods: {
    handleQuantityUpdate(payload) {
      this.$emit('updateOrder', payload);
    }
  },
 
};
</script>

<style scoped>
.category {
  padding: 1em 2em;
  text-align: center;
  width: 40%;

}
.row.closer .category{
  width: 35%;
  max-width: 500px;
}
.category h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
  
}

@media (max-width: 1200px) {
  .category {
    width: 50%;
  }
  .row.closer .category{
  width: 40%;

}

}
@media (max-width: 991px) {
  .category{
    width: 50%;
  }
  .category h2 {
    font-size: 20px
  }
  .row.closer .category{
  width: 40%;

}
}
@media (max-width: 723px) {
  .category{
    width: 70%;
  }
 .category h2 {
    font-size: 16px
  }
  .row.closer .category{
  width: 55%;

}

}
@media (max-width: 600px) {
  .category {
    width: 100%;
    padding: 1em;
  }
.row.closer .category{
  width: 100%;

}
  

}



/* Additional styles for the elements inside each category can go here */
</style>
