<template>
  <div class="order">
    <h2>Your Order</h2>
    <form @submit.prevent="submitOrder" class="order-form">
      <div v-for="item in orderItems" :key="item.meal.id" class="order-item">
        <p>{{ item.quantity }} x {{ item.meal.fields["Name"] }}</p>
      </div>

      <div v-if="isOrderItem">
        <p style="font-style:italic">Please note that a 70€ delivery fee will be charged</p>
      </div>

      <div class="details">
        <div class="form-group-inline">
          <div class="form-group">
            <input type="text" id="name" v-model="name" placeholder="Name">
          </div>
          <div class="form-group">
            <input type="text" id="firstName" v-model="firstName" placeholder="First name">
          </div>
        </div>
        
        <div class="form-group-inline">
          <div class="form-group">
           
              <VueDatePicker v-model="date" :enable-time-picker="false" placeholder="Date" format="dd/MM/yyyy"></VueDatePicker>

          </div>
          <div class="form-group">
            <input type="text" id="boat" v-model="boat" placeholder="Boat">
          </div>
        </div>
        
        <div class="form-group email-input">
          <input type="email" id="email" v-model="email" placeholder="Email*" required>
          <p v-if="email.length > 0 && !isValidEmail" class="error-message">Please enter a valid email address.</p>
        </div>

        <div class="form-group notes-input">
          <textarea id="notes" v-model="notes" placeholder="Notes"></textarea>
        </div>
      </div>

      <div class="submit-button-wrapper">
        <button type="submit" v-if="canSubmitOrder">
          <span v-if="isLoading">Processing...</span>
          <span v-else>Submit Order</span>
        </button>
      </div>
      
      <p v-if="submissionMessage">{{ submissionMessage }}</p>
    </form>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  components: {
    VueDatePicker
  },
  props: {
    orderItems: Array,
    isLoading: Boolean,
    submissionMessage: String
  },
  data() {
    return {
      email: '',
      name: '',
      firstName: '',
      boat: '',
      date: '',
      notes: '',
      today: new Date().toISOString().split('T')[0]
    };
  },
  computed: {
    totalPrice() {
      return this.orderItems.reduce((total, item) => total + (item.meal.fields["Prix de vente TTC"] * item.quantity), 0);
    },
    isValidEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    canSubmitOrder() {
      return this.isValidEmail && this.orderItems.length > 0 && this.email !== '';
    },
    isOrderItem() {
      return this.orderItems.length > 0;
    },
    dateformatted() {
      if (!this.date) {
        return '';
      }
      else{
      return this.date.toISOString().split('T')[0];
    }}
  },
  methods: {
    submitOrder() {
      if (!this.isValidEmail) {
        alert('Please enter a valid email address.');
        return;
      }
      this.$emit('order-submitted', {
        email: this.email,
        orderItems: this.orderItems,
        name: this.name,
        firstName: this.firstName,
        boat: this.boat,
        date: this.dateformatted,
        notes: this.notes
      });
    }
  }
};
</script>
<style scoped>
.order {
  background-color: #fff;
  padding: 2em;
  margin-top: 2em;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin: 2em auto;
}

@media screen and (max-width: 991px) {
  .order {
    width: auto;
    max-width: 90%;
  }
}

.order h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 1em;
}

.order-item p {
  font-size: 1em;
  margin: 0.5em 0;
}

.total-price {
  font-size: 1em;
  font-weight: bold;
  margin-top: 1em;
}

.details {
  margin-bottom: 1em;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-group-inline .form-group {
  width: 48%;
}

@media screen and (max-width: 550px) {
  .form-group-inline .form-group {
    width: 100%;
  }
}

.form-group {
  position: relative;
}




.form-group input,
.email-input input,
.notes-input textarea {
  display: block;
  width: 100%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 7px 0;
  font-family: "Poppins";
  font-size: 14px;
  box-sizing: border-box;
}



.notes-input textarea {
  height: 100px;
  margin-top: 1em;
}

.submit-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

button[type="submit"] {
  background-color: #0e3141;
  color: white;
  padding: 0.7em 2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins";
}

.error-message {
  color: red;
  font-size: 0.8em;
}
::placeholder{
  color: rgb(150, 150, 150)
}
</style>
